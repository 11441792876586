<template>
  <v-layout
      align-center
      justify-center
  >



    <v-container
        fluid
        style="
        position: absolute;
        top: 0px;
        left: 0px;
        "
    >


      <v-row>

        <v-card
            outlined
            elevation="7"
            style="
        margin: 5px;
        width: 90%;
        max-width: 350px;
"
        >

          <v-card-title>{{company}}</v-card-title>
          <v-card-text>
            <v-row style="text-align: left; padding-left: 14px;"><h3>{{street}}</h3></v-row>
            <v-row style="text-align: left; padding-left: 14px; padding-bottom: 15px;"><h3>{{zipcode}} {{city}}</h3></v-row>

          </v-card-text>

        </v-card>


        <CardTaskItemReadOnly
            v-for="item in items"
            :key="item.id"
            v-bind:task_items_id="item.id"
        ></CardTaskItemReadOnly>




        <v-card
            outlined
            elevation="7"
            style="
        margin: 5px;
        width: 90%;
        max-width: 350px;
"
        >

          <v-card-title></v-card-title>
          <v-card-text>
            <v-row style="text-align: left; padding-left: 0px;">
            <v-textarea
                v-model="pickup_note"
                outlined
                label="Bemerkungen zur Abholung"
            ></v-textarea>

            </v-row>

            <v-row style="text-align: left; padding-left: 0px; padding-bottom: 15px;">
              <v-text-field
                  v-model="rest"
                  type="number"
                  outlined
                  label="Restreifen"
              ></v-text-field>

            </v-row>

            <v-row style="text-align: left; padding-left: 0px; padding-bottom: 15px;">
              <v-text-field
              v-model="email"
              outlined
              label="E-Mail für Übergabeschein"
              ></v-text-field>

            </v-row>

          </v-card-text>

        </v-card>



        <v-card
            outlined
            elevation="7"
            style="
        margin: 5px;
        width: 90%;
        max-width: 350px;
"
        >


          <v-card-text>

            <drag-verify :width="300"
                         :height="60"
                         :text="'Bestätigen'"
                         :successText="'OK'"
                         :progressBarBg="'#383083'"
                         :color="'#fff'"
                         :textSize="'25px'"
                         :completed-bg="'#00C133'"
                         @passcallback="gotoNext"
            ></drag-verify>

          </v-card-text>

        </v-card>

      </v-row>



    </v-container>


  </v-layout>

</template>

<script>

import dragVerify from 'vue-drag-verify'
import CardTaskItemReadOnly from '@/components/CardTaskItemReadOnly'


export default {
  name: "Confirmation",

  components: {
    dragVerify,
    CardTaskItemReadOnly,
  },

  data () {

  return {

    snackbar: '',
    snackbar_title: '',
    snackbar_color: 'red',

    timeout: 1000,

    driver_id: localStorage.driver_id,

    temp_task_id: '',
    task_id: '',

    pickup_qty: 0,
    weight_total: 0,
    price_total: 0,

    company: '',
    street: '',
    zipcode: '',
    city: '',
    phone: '',

    email: '',

    note: '',
    pickup_note: '',
    rest: '',

    items: [],

  }
},



created(){

    if(this.$route.params.id != undefined) {
      this.task_id = this.$route.params.id
    }else{
      this.$router.push({ name: 'Home' })
    }

    this.loadData()

  },


  methods:{

    async saveData() {
      console.log("saveData")

      // Einstellungen
      var param = {
        table: "task",
        statement: "update",
        type: "",
        where: "true",
        selector: "id",
        value: this.task_id,
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      if (this.rest == ""){
        this.rest = 0
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
        pickup_note: this.pickup_note,
        rest: this.rest,
        rest_before: this.rest,
        email: this.email,
        driver_id: localStorage.driver_id,
        driver_name: localStorage.driver_name,
      }

      var result = await this.$func.queryAPI(param, data)
      console.log(result)


      // Einstellungen
      var param1 = {
        table: "task_items",
        statement: "update",
        type: "",
        where: "true",
        selector: "temp_task_id",
        value: this.temp_task_id,
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }



      // Daten -> Werden nur bei Insert oder Update gesendet
      var data1 = {
        pickup_timestamp: this.$moment(Date.now()).format("YYYY-MM-DD"),
      }

      var result1 = await this.$func.queryAPI(param1, data1)
      console.log(result1)



      if(result.length != 0){

        if (result["status"] == "404"){
          this.$router.push({ name: 'Error' })
        }

      }







    },

    async loadData(){
      console.log("loadData")

      // Einstellungen
      var param = {
        table: "task",
        statement: "select",
        type: "detail",
        where: "true",
        selector: "id",
        value: this.task_id,
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)
      console.log(result)


      if(result.length != 0){

        if (result["status"] == "404"){
            this.$router.push({ name: 'Error' })
        }

      }

      this.company = result[0]["company"]
      this.street = result[0]["street"]
      this.zipcode = result[0]["zipcode"]
      this.city = result[0]["city"]
      this.phone = result[0]["phone"]
      this.email = result[0]["email"]

      this.note = result[0]["note"]
      this.date = result[0]["planed_date"]

      this.order_qty = result[0]["order_qty"]
      this.article_name = result[0]["article_name"]

      this.temp_task_id = result[0]["temp_task_id"]


      // Einstellungen
      param = {
        table: "task_items",
        statement: "select",
        type: "list",
        where: "true",
        selector: "temp_task_id",
        value: this.temp_task_id,
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      data = {
      }

      result = await this.$func.queryAPI(param, data)
      console.log(result)

      if(result.length != 0){

        if (result["status"] == "404"){
          this.$router.push({ name: 'Error' })
        }

      }


      this.items = []

      for (var i1 = 0; i1 < result.length; i1++) {
        this.items.push({id:  result[i1]["id"], order_qty: result[i1]["order_qty"], pickup_qty: result[i1]["pickup_qty"], article_name: result[i1]["article_name"]})
      }

      console.log("items")
      console.log(this.items)

    },


    async gotoNext(){
      var audio = new Audio(require('@/assets/sounds/ding.mp3'));
      audio.play();

      await this.saveData()

      console.log("T1")

      // Lager Buchung + Kalkulation
      await this.setStockEntry()

      console.log("T2")

      // Speichert das Abholdatum, die Stückzahl + die Total Summen für Preis und Gewicht
      await this.setPickupDate()

      console.log("T3")


      setTimeout( () => this.$router.push({ name: 'Signature', params:{id: this.task_id}}), 2000);
    },


    // Vorbereitung für Lagereintrag, kalkuliert die Total Summen für Gewicht udn Preis
    async setStockEntry(){
      console.log("setStockEntry")

      // Einstellungen
      var param = {
        table: "task",
        statement: "select",
        type: "detail",
        where: "true",
        selector: "id",
        value: this.task_id,
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)
      console.log(result)

      if(result.length != 0){

        if (result["status"] == "404"){
          this.$router.push({ name: 'Error' })
        }

      }

      this.temp_task_id = result[0]["temp_task_id"]
      this.group_type_id = result[0]["group_type_id"]

      this.company = result[0]["company"]
      this.customer_id = result[0]["customer_id"]
      this.tour_id = result[0]["tour_id"]
      this.document_no = result[0]["document_no"]



      // Einstellungen
      param = {
        table: "task_items",
        statement: "select",
        type: "list",
        where: "true",
        selector: "temp_task_id",
        value: this.temp_task_id,
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      data = {
      }

      result = await this.$func.queryAPI(param, data)
      console.log(result)

      if(result.length != 0){

        if (result["status"] == "404"){
          this.$router.push({ name: 'Error' })
        }

      }

      this.pickup_qty = 0

      var pickup_qty, article_id, article_name, article_weight, article_price, weight_total, price_total, wasteno, rating, scrap_quantity_weight, scrap_quantity_qty, good_quantity_weight

// Geht alle Positionen durch uns erstellt einen Lagereintrag
      for (var i1 = 0; i1 < result.length; i1++) {

        if(result[i1]["pickup_qty"] != "0"){

          // Lager Buchung vornehmen (für jede task_item eine Lagerbuchung)

          article_id = result[i1]["article_id"]
          article_name = result[i1]["article_name"]
          pickup_qty = result[i1]["pickup_qty"]
          rating = result[i1]["rating"]

          article_weight = await this.getWeight(result[i1]["article_id"])
          article_price = await this.getPrice(result[i1]["article_id"], this.group_type_id)
          wasteno = await this.getWasteno(result[i1]["article_id"])

          if(article_weight != "0"){
            weight_total = article_weight * Number(pickup_qty)

            // Format Weight
            weight_total = weight_total.toString().replace(",",".")
            weight_total = +Math.abs(weight_total) // Wert Positiv
          }else{
            weight_total = 0
          }

          if(article_price != "0"){
            price_total = Number(article_price * Number(pickup_qty)).toFixed(2)
          }else{
            price_total = 0
          }

          // Ermittelt aktuelles Durschnittsreifengewicht
          var average_weight = await this.getAverageWeight()


          // Einstellungen
          var param2 = {
            table: "parameter",
            statement: "custom",
            type: "",
            where: "false",
            selector: "",
            value: "",
            short: "false", // Übermittlung nur von id
            // filter: "false",
            // filterfield: "",
            // filtervalue: "",
            // operator: "true",
            // Filter noch hinzufügen
            extension: "SELECT schluessel, wert FROM parameter WHERE schluessel LIKE '%rating%' ORDER BY 'schluessel' ASC",
          }


          // Daten -> Werden nur bei Insert oder Update gesendet
          var data2 = {}

          var result2 = await this.$func.queryAPI(param2, data2)

          var prozent


          switch (Number(rating)) {
            case 1:
              prozent = result2[0]["wert"]
              break;
            case 2:
              prozent = result2[1]["wert"]
              break;
            case 3:
              prozent = result2[2]["wert"]
              break;
            case 4:
              prozent = result2[3]["wert"]
              break;
            case 5:
              prozent = result2[4]["wert"]
              break;
          }

          if(weight_total != 0){

            // Schrottanteil wird ermittelt (nur bei Reifen)
            if(wasteno == "160103"){
              scrap_quantity_weight = weight_total - ((weight_total * prozent)  / 100)
              scrap_quantity_qty = Math.round(pickup_qty - ((pickup_qty * prozent)  / 100))

              good_quantity_weight = weight_total - scrap_quantity_weight

            }else{
              scrap_quantity_weight = weight_total
              scrap_quantity_qty = pickup_qty
              good_quantity_weight = 0
            }

          }else{
            scrap_quantity_weight = 0
            scrap_quantity_qty = 0
            good_quantity_weight = 0
          }

          console.log("scrap_quantity_weight")
          console.log(scrap_quantity_weight)


          // Fast alle Variablen für Stock Eintrag zusammen
          var stock_data = {
            typ: 'buy',
            customer_id: this.customer_id,
            name: this.company,
            tour_id: this.tour_id,
            task_id: this.task_id,
            document_no: this.document_no,
            driver_id: localStorage.driver_id,
            driver_name: localStorage.driver_name,
            worker: localStorage.driver_name,

            weight: Number(Number(weight_total) / 1000).toFixed(3),
            average_weight: average_weight,   // Durchschnittlicher Gewichtswert für diesen Artikel
            article_id: article_id,
            article_name: article_name,
            article_weight: article_weight,     // NEU!!!

            wasteno: wasteno,                   // NEU!!!
            rating: rating,                     // NEU!!!
            rating_value: prozent,              // NEU!!!

            good_quantity_weight: Number(Number(good_quantity_weight) / 1000).toFixed(3), // NEU!!!

            scrap_quantity_weight: Number(Number(scrap_quantity_weight) / 1000).toFixed(3), // NEU!!!
            scrap_quantity_qty: scrap_quantity_qty,       // NEU!!!

            note:  pickup_qty +  " " + article_name,      // + " (Tour: " + this.tour_id + ")",

            qty:  pickup_qty,
            price: price_total,

            date: this.$moment(Date.now()).format("DD.MM.YYYY"),
            date_timestamp: this.$moment(Date.now()).format("YYYY-MM-DD"),
            time: this.$moment(Date.now()).format("HH:mm:ss"),
          }

          // Schreibe Stock Eintrag
          await this.saveStockEntry(stock_data)

          this.weight_total = Number(Number(this.weight_total) + (Number(weight_total) / 1000)).toFixed(3)
          this.price_total = Number(Number(this.price_total) + Number(price_total)).toFixed(2)
          this.pickup_qty = Number(this.pickup_qty) + Number(result[i1]["pickup_qty"])

        }

      }


    },

    async getAverageWeight(){

      // Einstellungen
      var param = {
        table: "parameter",
        statement: "select",
        type: "list",
        where: "true",
        selector: "schluessel",
        value: "average_weight",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {}

      var result = await this.$func.queryAPI(param, data)
      // console.log(result)


      // console.log("average_weight")
      // console.log(result[0]["wert"])

      return result[0]["wert"]


    },


    // Speichert den Lagereintrag
    async saveStockEntry(data){

      // Parameter
      var param = {
        table: "stock",
        statement: "insert",
        type: "",
        where: "",
        selector: "",
        value: "",
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      console.log(data)

      var result = await this.$func.queryAPI(param, data)
      console.log(result)



      // return  this.id = result["value"]

    },

    // Liefert das Artikelgewicht - Falls nicht vorhanden dann 0
    async getWeight(article_id){
      console.log("getWeight")
      console.log(article_id)

      // Einstellungen
      var param = {
        table: "article",
        statement: "select",
        type: "detail",
        where: "true",
        selector: "id",
        value: article_id,
        short: "false", // Übermittlung nur von id
        debug: "false",
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)
      console.log(result)

      return result[0]["weight"] ?? 0

    },

    // Liefert die Entsorgungsnummer - Falls nicht vorhanden dann 0
    async getWasteno(article_id){
      console.log("getWasteno")
      console.log(article_id)

      // Einstellungen
      var param = {
        table: "article",
        statement: "select",
        type: "detail",
        where: "true",
        selector: "id",
        value: article_id,
        short: "false", // Übermittlung nur von id
        debug: "false",
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)
      console.log(result)

      return result[0]["wasteno"] ?? 0

    },


    // Liefert den Artikelpreis nach Kundengruppe - Falls nicht vorhanden dann 0
    async getPrice(article_id, group_type_id){
      console.log("getPrice")

      // Einstellungen
      var param = {
        table: "group_price",
        statement: "select",
        type: "list",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        debug: "false",
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "WHERE article_id='" + article_id + "' AND group_type_id='" + group_type_id + "'",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)
      console.log(result)

      if(result.length == "0"){
        return "0"
      }

      return result[0]["price"]

    },

    // Speichert das Upholdatum, Abholstückzahl + Total Beträge für Preis und Gewicht
    async setPickupDate(){

      // Einstellungen
      var param = {
        table: "task",
        statement: "update",
        type: "",
        where: "true",
        selector: "id",
        value: this.task_id,
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
        pickup_date: this.$moment(Date.now()).format("DD.MM.YYYY"),
        pickup_time: this.$moment(Date.now()).format("HH:mm:ss"),
        pickup_timestamp: this.$moment(Date.now()).format("YYYY-MM-DD HH:mm:ss"),

        pickup_qty: this.pickup_qty,
        weight_total: this.weight_total,
        price_total: this.price_total,
      }

      var result = await this.$func.queryAPI(param, data)
      console.log(result)


      // ToDo: Check is result OK



    }


  },









}
</script>

<style scoped>

</style>
